@import "~react-image-gallery/styles/scss/image-gallery.scss";

.itemContent{
    display: flex;
    flex-wrap: nowrap;

    .rightcol{
        width: 300px;
        margin: 0;
        padding: 0 0 0 16px;

        .top{
            margin: 0;
        }
        .itemId{
            font-size: small;
        }

        .price{
            color: #212062;
            font-family: 'Handel Gothic';
            font-size: 34px;
        }
    }
}

.buttonContainer{
    display: flex;
    flex-wrap: wrap;
    margin-top: 36px;
    margin-bottom: 36px;
}

.button{
    margin-top: 16px;
    margin-bottom: 36px;
    margin-right: 12px;
    width: 5rem;
    padding: 1rem;
    border-radius: 8px;
    font-family: 'Handel Gothic';
    font-size: 16px;
    border: 2px solid rgb(33,32,98);
    text-decoration: none;
    text-align: center;   
}
.button:hover {
    background-color: rgb(33,32,98);
    color: #FFF;
    text-decoration: none;
}

.red{
    color: rgb(204,0,0);
    border: 2px solid rgb(204,0,0);
}

.red:hover {
    background-color: rgb(204,0,0);
    color: #FFF;
    text-decoration: none;
}

@media screen and (max-width: 850px){
    .itemContent{
        flex-wrap: wrap;
    }
}