@import 'react-calendar/dist/Calendar.css';

.react-calendar{
  width: unset;
  margin-top: 16px;
  border-radius: 4px;
}

.react-calendar__navigation{
  width: 100%;

  .react-calendar__navigation__label__labelText {
    font-family: 'Handel Gothic';
    font-size: 20px;
    color: rgb(33,32,98)
  }
}

.react-calendar__tile {
  height: 120px;
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  border: 1px solid #dbdbdb !important;
  font-family: 'Handel Gothic';
}

.calendar-tile--content {
  font-size: 12px;
  width: 100%;
  text-align: center;
  background-color: rgb(33,32,98);
  border: 1px solid rgb(33,32,98);
  border-radius: 4px;
  color: #fff;
  

  .mobile{
    margin: 8px;
    color: rgb(33,32,98);
  }

  .course {
    font-size: 12px;
    padding: 4px;
    font-family: 'Handel Gothic';
    color: #FFF;
    margin: 0;
  }

  .time {
    font-size: 12px;
    font-family: 'Handel Gothic';
    color: #FFF;
    margin: 0 0 4px 0;
  }

  abbr[title] {
    color: green;
    display: block;
    width: 100%;
    cursor: default;
  }
}

.react-calendar__month-view__weekdays__weekday{
  cursor: pointer;
  abbr: {
    cursor: pointer;
  }
}

.react-calendar__navigation__label:disabled{
  background-color: unset !important;
  color: black !important;
}

abbr[title],
abbr[data-original-title] {
  cursor: default !important;
  text-decoration: none !important;
}

.modal-backround {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color:rgba(0,0,0,0.75);
  z-index: 100;
}

.modal {
  display: flex;
  flex-wrap: wrap;
  max-width: 80%;
  background-color: #FFF;
  z-index: 101;
  border: 1px solid #FFF;
  border-radius: 4px;

  .modalButtons{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    height: 32px;
    .closeButton {
      background: none;
      border: none;
      color: #333;
      padding: 4px;
    }
  }

  .modal-content {
    display: flex;
    font-family: 'Handel Gothic';
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;

    p {
      color: rgb(33,32,98);
      text-align: center;
      width: 100%;
    }
    
  }
}

.react-calendar__tile--active{
  background-color: transparent !important;
  color: transparent !important;
}

@media screen and (max-width: 670px) {
  .react-calendar__tile {
    height: 80px;
  }

  .calendar-tile--content {
    background-color: #FFF;
    border: none;
  }

  .react-calendar__viewContainer {
    pointer-events: all !important;
  }
}