html {
    height: 101%;
}

body{
    font-family: Verdana, Arial, Helvetica, sans-serif;
    line-height: 1.4rem;
    margin: 0;
}

h1, h2, h3, h4 {
    font-family: 'Handel Gothic';
    color:rgb(33,32,98);// rgb(204,0,0);
}

a {
    color: rgb(33,32,98);
}

    a:hover {
        color: #777;
    }

.underline{
    text-decoration: underline;
}

.body-content {
    max-width: 1168px;
    margin: auto;
    padding: 0 20px 20px 20px;
}

h1 {
    line-height: 1.7rem;
}

p, li{
    font-size: 1.1rem;
    color:#333;
}

header {
    background: linear-gradient(to bottom, rgb(33,32,98), rgba(0,0,0,1));
    font-family: roboto;

    .container{
        max-width: 1168px;
        margin: auto;
    }
}

.header {
    font-family: 'Handel Gothic';
    color: #fff;
    font-size: 24px;
    padding: 8px;
}

nav {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
}

.nav-links {
    display: flex;
    align-items: flex-end;
    font-family: 'Handel Gothic';

    div {
        text-align: center;
        padding: 1rem;
    }

    a {
        color: #FFF;
        padding: 8px 8px;
        font-size: 18px;
        border-bottom: 4px solid rgba(0,0,0,0.0);
        text-decoration: none;
    }

    a:hover {
        font-weight: 600;
        text-decoration: none;
        border-bottom: 4px solid #FFF;
    }

    .icon {
        display: none;
    }
}
    .nav-links .logo, .nav-links .logo:hover {
        border-bottom: 4px solid rgba(0,0,0,0.0);
    }

nav a .logo {
    height: 108px;
    width: 127px;
    background-image: url(../../../wwwroot/images/NAA-logo.jpg);
    background-repeat: no-repeat;
    background-size: contain;
}

.nav-links .logo img {
    width: 100%;
}

.mobile-logo {
    display: none;
}

.hamburger-button {
    width: 2rem;
    display: none;
}

.homepage-gallery.slide {
    max-height: 500px;
    overflow: hidden;
}

.sales-gallery.slide {
    max-height: 600px;
    overflow: hidden;
}

.sales-buttons{
    text-align: center;
    display: flex;

    .specials {
        color: rgb(204,0,0);
        border: 2px solid rgb(204,0,0);
  
    }

    .specials:hover {
        background-color: rgb(204,0,0);
        color: #FFF;
        text-decoration: none;
    }
}

.homepage-hero {
    height: 31rem;
    width: 100%;
    background-image: url(../../../wwwroot/images/new-american-arms-interior.jpg);
    background-repeat: no-repeat;
    background-position: center;
    text-align: right;

    h1 {
        span {
            font-size: 36px;
        }
    }

    .container.body-content {
    display: flex;
    justify-content: flex-end;
    }
}

.homepage-content {
    a {
        margin-top: 16px;
        margin-bottom: 36px;
        margin-right: 12px;
        width: 12rem;
        display: block;
        padding: 1rem;
        border-radius: 8px;
        font-family: 'Handel Gothic';
        font-size: 16px;
        border: 2px solid rgb(33,32,98);
        text-decoration: none;
        text-align: center;   
    }

    a:hover {
        background-color: rgb(33,32,98);
        color: #FFF;
        text-decoration: none;
    }

    .sales-buttons{
        display: flex;
    }
}

.homepage-gallery-text {
    font-family: 'Handel Gothic';
    font-size: 24px !important;
}

.homepage-gallery > img {
    transform: translate(0, -10%);
}

.sales-gallery > img {
    transform: translate(0, -10%);
}

.homepage-content-left {
    width: 20rem;
    flex-grow: 1;
}

    .homepage-content-left div {
        flex: 1 1 100%;
    }

.homepage-content-right {
    /*flex-shrink: 0;
    width: 320px;*/
    float: right;
    border: 2px solid rgb(33,32,98);
    border-radius: 5px;
    margin-left: 1rem;
}

.homepage-bottom {
    text-align: center;

    .map-container {
        width: 100%;
    }
}

.homepage-bottom-content {
    .storefront {
        margin: 1rem auto;
        max-width: 696px;
    }

    .storefront { 
        img {
            width: 100%;
        }
    }

    .map {
        width: 100%;
    }
}

.success {
    padding: 10rem 0 20rem 0;
    text-align: center;
}

#twitter-widget-0 {
    width: 100% !important;
}

#contactForm {
    max-width: 400px;
    margin: auto;
}

a.email {
    display: inline;
    border: none;
}

    a.email:hover {
        text-decoration: underline;
        background: none;
        color: rgb(33,32,98);
    }

.service-image {
    width: 300px;
    float: right;
    margin-left: 4rem;
}

.precision-gallery {
    max-height: 30rem;
    margin: 5rem auto;
    text-align: center;
    display: inline;

    img {
        width: 100%;
        margin: auto;
    }
}

.logo-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.logo-gallery-item {
    height: 64px;
    margin: 1rem;

    img {
        height: 100%;
    }
}

.homepage-image {
    width: 100%;
}

.map {
    margin: auto;
    max-width: 70rem;

    iframe {
        width: 100%;
    }
}

.contact-body {
    display: flex;
    flex-wrap: wrap;

    .map {
        flex-grow: 2;
    }
}

.contact-body-left {
    margin-right: 2rem;
    min-width: 20rem;
}

footer {
    padding-top: 4px;
    background-color: #111;
    padding-bottom: 3rem;
    color: #FFF;

    a {
        margin-right: 1rem;
        margin-bottom: 1rem;
        color: #FFF;
    }

    p {
        color:#FFF;
    }

    .legal {
        text-align: center;
    }

    .footer-links{
        display: flex;
        justify-content: center;
    }
}
.centered {
    display: block;
    margin: auto;
}

.training-hero img {
    float: right;
    margin-left: 1rem;
}

.training-item img {
    float: right;
}

.calendar-container {
    margin: auto;
    .calendar{
        width: 100%;
        max-width: 600px;
    }
}

.video-container{
    padding-bottom: 16px;
}

.services{
    div{
        margin-bottom: 40px;
    }
}

.extra{
    color: rgb(204,0,0);
}

.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } 
.embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }


@media screen and (max-width: 850px) {
    .homepage-gallery, .sales-gallery > img {
        transform: translate(0, 0);
    }

    .sales-buttons{
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 767px) {
    .mobile-hidden {
        display: none;
    }

        header .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0;
        }

    nav {
        align-items: center;
    }

        nav a .logo {
            height: 50px;
            width: 60px;
            //background-image: url(/images/NAAicon.svg);
            background-image: url(../../../wwwroot/images/NAA-logo.jpg);
        }

    .nav-links {
        display: none;
    }

        .nav-links a {
            display: block;
            border: none;
        }

            .nav-links a:hover {
                background-color:rgb(33,32,98);
                font-weight: 400;
                border: none;
            }

    .mobile-logo {
        display: inline;
        color: #FFF;
        font-family: 'Handel Gothic';
        margin: 1.3rem 0;
        font-size: 24px;
        flex-grow: 1;
    }

    nav .nav-links .icon {
        display: block;
    }

    .hamburger-button {
        display: block;
        width: 50px;
        height: 50px;
        background: rgb(33,32,98);
        border: none;
        color: #FFF;
        padding-top: 6px;
    }

        .hamburger-button:focus {
            outline: none;
        }

        .hamburger-button.responsive {
            background-color: #000;
        }

        .hamburger-button:hover {
            background-color: #FFF;
            color: rgb(33,32,98);
        }

    .nav-links.responsive {
        position: absolute;
        display: block;
        width: 20rem;
        background-color: #000;
        z-index: 5;
        right: 0;
        top: 60px;
        transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    }

    .homepage-hero {
        height: 15rem;
        background-position: 28% 29%;
    }

    .homepage-content-right {
        width: 100%;
        border: none;
    }

    .homepage-content-body > * {
        flex: unset;
    }
}

@media screen and (max-width: 364px) {
    .mobile-logo {
        font-size: 21px;
    }
}

@font-face {
    font-family: 'Handel Gothic';
    src: url(../../../wwwroot/fonts/HandelGothic.woff);
}

.disclaimer{
    font-size: 14px;
}

.emailonly{
    margin-left: 6rem;
}

ol{
    li{
        margin-bottom: 8px;
    }
}

.italic{
    font-style: italic;
}