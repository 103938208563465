.searchPage{
padding: 8px;
}

.searchBar{
    margin: 16px 0;

    .centered{
        margin: auto;
        max-width: 300px;
    }

    .searchInput{
        height: 32px;
        width: 100%;
        border: 2px solid rgb(33,32,98);
        border-radius: 8px;
    }
}

.search{
    display: flex;

    .searchMenu{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .sideBar{
        flex-shrink: 0;
        width: 300px;
        margin: 8px;        
    }

    .categories{
        list-style: none;
        padding: 0;
        font-family: 'Handel Gothic';
    }
    .categoryItems{
        a{
            text-decoration: none;
        }
    }

    .searchContainer{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        .itemTile{
            display: block;
            text-decoration: none;
            border: 1px solid rgb(33,32,98);
            max-width: 400px;
            border-radius: 8px;
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
            padding: 8px;

            &:hover{
                border: 1px solid rgb(204,0,0);
                color: rgb(204,0,0);

                h2{
                    color: rgb(204,0,0);
                }
                .price{
                    color: rgb(204,0,0);
                }
            }

            .imageContainer{
                height: 300px;
                width: 400px;
                img{
                    width: 100%;
                }
            }


            .price{
                color: #212062;
                font-family: 'Handel Gothic';
                font-size: 34px;
                text-align: end;
            }

            .tileBottom{
                display: flex;
                justify-content: space-between;
                align-items: center;

                .hotBuy{
                    color: rgb(204,0,0);
                    font-family: "Handel Gothic";
                    font-size: 34px;
                }
            }
            
        }
    }
}

.filter-btn{
        width: 80px;

}

.filter-btn, .filterClose-btn{
    font-family: 'Handel Gothic';
    font-size: 16px;
    background-color: #fff;
    border: 1px solid rgb(33,32,98);
    text-decoration: none;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 4px;
}

.mobileSidebar{
    position: absolute;
    left: 0;
    top: 0;
    background-color: #fff;
    height: 100%;
    overflow-y: scroll;
    margin-left: -300px;
    transition: margin ease .6s;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
    font-family: 'Handel Gothic';
    color: rgb(33,32,98);
    font-size: 24px;

    &-header{
        position: fixed;
        width: 280px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        padding: 8px;
        border-bottom: 1px solid rgb(33,32,98);
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
    }

    .sideBar{
        margin: 48px 0 0 0;

        .categories{
            padding: 0 8px 0 8px;
        }
    }

    li{
        padding: 8px 0;
    }
}

.mobileSidebar.isOpen{
    margin-left: 0;
}

@media screen and (min-width: 767px) {
    .filter-btn{
        display: none;
    }

    .mobileSidebar{
        display: none;
    }
}